import React from "react";
import { Layout, UsedBikeList } from "components";
import { Hero } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { UsedMotorcycleQuery } from "codegen";
import { PageProps, graphql } from "gatsby";

interface UsedMotorcycleContext {
  pageNumber: number;
  humanPageNumber: number;
  skip: number;
  limit: number;
  numberOfPages: number;
  previousPagePath?: string;
  nextPagePath?: string;
}

const UsedMotorcycleList = ({
  data: { page, posts },
}: PageProps<UsedMotorcycleQuery, UsedMotorcycleContext>): JSX.Element => {
  if (page) {
    const { acfLayout, seo, content } = page;

    return (
      <Layout pageTitle={page.title} seo={seo}>
        <>
          {acfLayout.layout &&
            Object.keys(acfLayout.layout).length > 0 &&
            acfLayout.layout?.map((item, index) => {
              if (item.fieldGroupName === "ContentNode_Acflayout_Layout_Hero") {
                return <Hero data={item} key={`hero__${index}`} />;
              }
            })}
          <UsedBikeList bikes={posts.nodes} />
        </>
      </Layout>
    );
  }
};

export default WordPressPreview({ postType: "pages" })(UsedMotorcycleList);

export const query = graphql`
  query UsedMotorcycle($id: String!, $postsPerPage: Int!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      ...SEOPageQuery
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
        }
      }
    }
    posts: allWpUsedMotorcycle(sort: { fields: [date], order: DESC }, limit: $postsPerPage) {
      nodes {
        ...UsedMotorcyclesFields
      }
    }
  }
`;
